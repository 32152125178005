<template>
  <div>
    <Landing />
  </div>
</template>

//

<script>
import Landing from "./components/Landing.vue";

export default {
  name: "App",
  components: {
    Landing,
  },
};
</script>

<style src="./assets/css/tailwind.css"></style>
