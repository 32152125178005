<template>
  <div
    class="flex justify-between items-center px-7 sm:px-10 xl:px-20 py-10 bg-lightblue lg:space-x-5 space-x-0"
  >
    <div class="flex justify-between w-full lg:w-1/12">
      <a href="/" class="flex items-center w-5/12 sm:w-44 space-x-3"
        ><img src="@/assets/images/logo.svg"
      /></a>
      <button class="relative z-30" @click="togglenav">
        <img src="@/assets/images/menu.svg" class="lg:hidden" />
      </button>
    </div>
    <div
      class="absolute z-30 inset-0 bg-black opacity-30"
      :class="{ hidden: !navopen, 'block lg:hidden': navopen }"
      @click="togglenav"
    ></div>
    <div
      class="nav-toggler"
      :class="{
        'opacity-0 -z-10 lg:z-40 lg:opacity-100': !navopen,
        'opacity-100 z-40 ': navopen,
      }"
    >
      <div
        class="
          lg:justify-between
          space-y-7
          lg:space-y-0
          flex flex-col
          lg:flex-row lg:w-7/12
          font-circular
        "
      >
        <a
          href="https://App.zoropay.com"
          class="text-blue-gray-1  hover:no-underline hover:text-blue transition duration-300"
          >Tuition Funding</a
        >
        <a
          href="https://app.zoropay.com/foreign-tuition"
          class="text-blue-gray-1  hover:no-underline hover:text-blue transition duration-300"
          >Abroad Tuition</a
        >
        <a
          href="https://app.zoropay.com/affiliated-school"
          class="text-blue-gray-1  hover:no-underline hover:text-blue transition duration-300"
          >Affiliated School</a
        >
        <a
          href="#about"
          class="text-blue-gray-1  hover:no-underline hover:text-blue transition duration-300"
          >Our Mission</a
        >
        <a
          href="https://blog.zoropay.com/"
          class="text-blue-gray-1  hover:no-underline hover:text-blue transition duration-300"
          >Blog</a
        >
      </div>
      <div class="lg:space-x-5 space-x-4 mt-8 lg:mt-0 lg:w-5/12 xl:w-4/12">
        <button
          v-on:click="login"
          class="
            bg-transparent
            text-blue
            border
            border-blue
            py-2
            px-6
            rounded-full
            focus:outline-none
            hover:shadow-lg transition duration-500
          "
        >
          Sign In
        </button>
        <button
          v-on:click="signUp"
          class="
            bg-blue
            text-white
            border
            border-blue
            py-2
            px-2
            md:px-6
            rounded-full
            focus:outline-none
            hover:shadow-lg transition duration-500
          "
        >
          Create Account
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      navopen: false,
    };
  },
  methods: {
    togglenav() {
      this.navopen = !this.navopen;
    },
    login: function() {
      window.location.href = "https://app.zoropay.com/login";
    },
    signUp: function() {
      window.location.href = "https://app.zoropay.com/get-started";
    },
  },
};
</script>

<style scoped>
.nav-toggler {
  @apply justify-between lg:items-center w-10/12 sm:w-6/12 lg:w-9/12 absolute bg-white lg:static px-6 sm:px-10 lg:px-0 py-9 lg:py-0 lg:bg-transparent rounded-3xl flex flex-col lg:flex-row top-20 right-5 transition duration-300 ease-in-out;
}
</style>
