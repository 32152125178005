<template>
  <div>
    <div class="bg-lightblue lg:h-screen pb-14 lg:pb-0 font-circular">
      <!--	<div class="p-2 sm:p-5 bg-warning text-white text-center text-xs ">

				The platform is currently down for scheduled maintenance, we’ll
				be back up shortly, with something special in store for you. We
				apologise for the inconvenience
			</div> 
			-->
      <Nav />

      <!-- hero -->
      <h1
        class="
          text-darkblue
          xl:text-7xl
          lg:text-6xl  
          sm:text-5xl
          text-4xl
          font-medium
          pt-30 <!-- 36...original size -->
          sm:w-8/12
          w-11/12	
          mx-auto
          text-center
        "
      >
        Education is priceless, now you can afford it with
        <span class="text-blue font-bold">ZoroPay</span>
      </h1>

      <p
        class="
          text-blue-gray-1
          lg:text-2xl
          sm:text-lg
          mt-6
          sm:w-5/10
          w-8/12
          mx-auto
          text-center
        "
      >
        We provide access to stress free edu-funding by connecting learners to a global network of licensed
        lending institutions.
      </p>
     
      <button
        v-on:click="quickLP"
        class="
				my-10
				block
				mx-auto
            bg-blue
            text-white
            border
            border-blue
            py-2
          text-center
            px-6
            rounded-full
            focus:outline-none
            hover:shadow-lg transition duration-500
          "
      >
        Get Started
      </button>
      <div class="hidden text-center mx-auto lg:mx-auto lg:block">
        <div>
          <img src="../assets/images/arrow.svg" alt="arrow" class="animate-bounce block mx-auto lg:pt-14" />
        </div>
      </div>
      <div class="flex justify-between items-end sm:px-10 px-3 mt-10 sm:mt-0">
        <div class="sm:w-3/12 w-5/12">
          <img
            src="https://res.cloudinary.com/zoropayhq/image/upload/v1644497576/landing-page/landing-stock-left_nyyggs.png"
            alt=""
            class="lg:-mt-44"
          />
        </div>

        <div class="sm:w-3/12 w-5/12">
          <img
            src="https://res.cloudinary.com/zoropayhq/image/upload/v1644497577/landing-page/landing-stock-right_hmiet0.png"
            alt=""
            class="lg:-mt-44"
          />
        </div>
      </div>
    </div>

    <!-- trusted by  -->
    <div class="py-10 font-circular">
      <p class="text-blue-gray-1 text-xs text-center tracking-wide-title">
        Coverage of 1000+ institutions in 18 countries around the world.
      </p>
      <div
        class="
          mt-8
          sm:mt-10
          flex
          w-6/12
          justify-center
          sm:space-x-20
          space-x-10
          mx-auto
        "
      >
        <img
          src="https://res.cloudinary.com/zoropayhq/image/upload/v1664556458/landing-page/covenant_n1q2ap.png"
          alt="covenant"
          class="sm:h-20 h-10 rounded-xl"
        />
        <img
          src="https://res.cloudinary.com/zoropayhq/image/upload/v1664556457/landing-page/babcock_l5srfc.jpg"
          alt="babcock"
          class="sm:h-20 h-10 rounded-xl "
        />
        <img
          src="https://res.cloudinary.com/zoropayhq/image/upload/v1664550932/landing-page/PaveHq_vbab2y.jpg"
          alt="babcock"
          class="sm:h-20 h-10 rounded-xl "
        />
        <!-- <img
          src="https://res.cloudinary.com/zoropayhq/image/upload/v1644497578/landing-page/image_4_ukbiwj.png"
          alt="pan"
          class="sm:h-20 h-10"
        /> -->
        <!-- <img
          src="https://res.cloudinary.com/zoropayhq/image/upload/v1664550932/landing-page/mpower_t8k2av.png"
          alt="afe"
          class="sm:h-20 h-10"/> -->
        <!-- <img
          src="https://res.cloudinary.com/zoropayhq/image/upload/v1644497577/landing-page/image_3_u55q4g.png"
          alt="afe"
          class="sm:h-20 h-10"
        /> -->
      </div>
    </div>

    <!-- we pay your school fees for you -->
    <div
      id="loans"
      class="
        flex flex-col
        lg:flex-row
        justify-between
        xl:pl-36
        sm:pl-24
        pl-5
        pr-5
        py-16
        font-circular
        text-center
        sm:text-left
      "
    >
      <div class="flex flex-col justify-between xl:mt-20 lg:w-6/12 sm:w-8/12">
        <div>
          <h2 class="section-title">
           Our local funding covers all levels of education in Nigeria
          </h2>
          <p class="section-sub font-normal">
            Get access to stress-free tuition funding for any level of education in Nigeria, 
            and pay later in installments
          </p>
          <img src="../assets/images/we-pay.svg" alt="we-pay" class="xl:mt-14 mt-7" />
        </div>
        <div class="mx-auto w- sm:mx-0 mt-14 lg:mt-12">
          <button v-on:click="quickLP" class="cta-button">
            Apply now
          </button>
        </div>    
      </div>
      <div class="mt-16 lg:mt-0 lg:w-5/12 w-4/6 mx-auto">
        <img
          src="https://res.cloudinary.com/zoropayhq/image/upload/v1644497576/landing-page/screen-pay-school-fees_pv9ysi.png"
          alt=""
        />
      </div>  
    </div>

    <!-- stay prepared months ahead -->
    <div
      id="save"
      class="
        flex flex-col
        lg:flex-row
        justify-between
        xl:pr-36
        sm:pr-24
        sm:pl-0
        pl-5
        pr-5
        pt-16
        pb-28
        font-circular
      "
    >
      <div
        class="
          hidden
          sm:block
          mt-8
          sm:w-5/6
          lg:mt-0
          lg:w-6/12
          order-2 lg:order-1
        "
      >
        <img
          src="https://res.cloudinary.com/zoropayhq/image/upload/v1644497576/landing-page/stay-prepared-img_z3n7wl.png"
          alt=""
          class="w-full h-full object-cover"
        />
      </div>
      <div
        class="
          sm:text-left
          flex flex-col
          justify-between
          xl:mt-8
          lg:w-5/12
          sm:w-8/12
          self-center
          order-1 lg:order-2
        "
      >
        <div class="mt-3 text-center sm:text-left ">
          <h2 class="section-title">Get Started With Our International Funding</h2>
          <div class="mt-8 sm:hidden -ml-5">
            <img
              src="https://res.cloudinary.com/zoropayhq/image/upload/v1644497579/landing-page/stay-prepared-screen_jcl1ox.png"
              alt=""
            />
          </div>
          <p class="section-sub font-normal mx-auto sm:mx-0">
            With an admission to any of our 380+ partnered universities in the US and Canada you are only a click away from getting funding.
          </p>
          <div class="mt-12 sm:w-10/12 relative">
            <div
              class="text-left border border-teal rounded-2xl py-7 xl:px-10 px-7 space-y-4 text-blue-gray-1 relative z-20 bg-white"
            >
              <div class="flex space-x-3 items-center relative z-20">
                <img src="../assets/images/tick.svg" alt="tick" />
                <p>We pay directly to your school</p>
              </div>
              <div class="flex space-x-3 items-center">
                <img src="../assets/images/tick.svg" alt="tick" />
                <p>No co-signer or collateral needed</p>
              </div>
              <div class="flex space-x-3 items-center">
                <img src="../assets/images/tick.svg" alt="tick" />
                <p>You pay back when you get a job after Graduation</p>
              </div>
            </div>
            <div class="absolute top-10 bottom-0 inset-x-5 z-10 shadow-steps"></div>
          </div>
        </div>
        <div class="text-center sm:text-left mt-14 lg:mt-12">
          <button v-on:click="Abroad" class="cta-button">
            Get Started
          </button>
        </div>
      </div>
    </div>

    <!-- plan right -->
    <div
      class="
        flex flex-col
        lg:flex-row
        justify-between
        xl:pl-36
        sm:pl-24
        pl-5
        sm:pr-0
        pr-5
        py-16
        font-circular
        text-center
        sm:text-left
      "
    >
      <div class="flex flex-col justify-between xl:mt-20 lg:w-5/12 sm:w-8/12">
        <div>
          <h2 class="section-title">
            Plan Right for a Bright Future
          </h2>
          <p class="section-sub font-normal mx-auto sm:mx-0">
            With Zoropay trust funds, you can set up long-term assurance for their education, secured by us.
          </p>
          <div class="mt-12 sm:w-10/12 relative">
            <div
              class="text-left border border-teal rounded-2xl py-7 xl:px-10 px-7 space-y-4 text-blue-gray-1 relative z-20 bg-white"
            >
              <div class="flex space-x-3 items-center relative z-20">
                <img src="../assets/images/tick.svg" alt="tick" />
                <p>Stay ready for larger fees</p>
              </div>
              <div class="flex space-x-3 items-center">
                <img src="../assets/images/tick.svg" alt="tick" />
                <p>Zero worries about future school fees</p>
              </div>
              <div class="flex space-x-3 items-center">
                <img src="../assets/images/tick.svg" alt="tick" />
                <p>Money is locked in until maturity date</p>
              </div>
            </div>
            <div class="absolute top-10 bottom-0 inset-x-5 z-10 shadow-steps"></div>
          </div>
        </div>
        <div class="mx-auto w- sm:mx-0 mt-14 lg:mt-12">
          <button v-on:click="quickLP" class="cta-button">
            Apply now
          </button>
        </div>
      </div>
      <div class="mt-8 lg:mt-0 -mr-5 sm:mr-0 lg:w-6/12">
        <img
          src="https://res.cloudinary.com/zoropayhq/image/upload/v1644497575/landing-page/plan-right-img_j7e1t5.png"
          alt=""
          class="w-full h-full object-cover"
        />
      </div>
    </div>

    <!-- video -->
    <!-- NOTE TO ZOROPAY: REPLACE THE IMAGE WITH A VIDEO EMBED: -->
    <div id="learn" class="py-10 font-circular">
      <p
        class="
          text-blue-gray-1 text-xs text-center
          uppercase
          tracking-wide-title
        "
      >
        get started
      </p>
      <h2 class="font-medium text-2xl lg:text-4xl text-center">
        Watch the Video
      </h2>
      <div class="w-full flex justify-center">        
        <iframe width="85%" height="483" src="https://www.youtube.com/embed/cnpoK9yIuBE" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>     
      </div>
    </div>

    <!--why zoropay-->
    <div class="bg-blue2 py-16 xl:px-28 sm:px-16 px-8 relative font-circular">
      <div class="flex flex-col sm:flex-row sm:items-end">
        <div class="sm:w-6/12">
          <h2 class="section-title">
            <span class="text-white">
              WHY ZOROPAY <br />
              <span class="text-lightblue2">No stories.</span>
            </span>
          </h2>
        </div>

        <!--	<div class="mt-6 sm:mt-0 sm:w-6/12 text-white lg:text-lg">
					<p class="xl:w-9/12 w-11/12">
						We are trusted by parents of top leading educational
						institutions in the country and are 100% committed to
						the protection of your account and funds.
					</p>
				</div>
				-->
      </div>

      <div class="flex flex-col sm:flex-row">
        <div class="sm:w-6/12">
          <div class="mt-20 flex items-start space-x-6 xl:w-9/12 w-11/12">
            <img src="../assets/images/tick.svg" alt="tick" class="mt-1" />
            <div class="text-white">
              <h3 class="font-medium text-2xl">
                Low Interest Rates
              </h3>
              <p class="mt-4 lg:text-base text-sm">
                Zoropay has met with partners to get the lowest interest rates possible for your benefit
              </p>
            </div>
          </div>
          <div class="mt-10 flex items-start space-x-6 xl:w-9/12 w-11/12">
            <img src="../assets/images/tick.svg" alt="tick" class="mt-1" />
            <div class="text-white">
              <h3 class="font-medium text-2xl">
                Multiple lending partners
              </h3>
              <p class="mt-4 lg:text-base text-sm">
                With our automatching technology, we connect you to a pool of unbiased lenders where your applications
                are auto assigned to a lender based on your matching criteria, giving you a better fighting chance of
                receiving funding for your child.
              </p>
            </div>
          </div>
        </div>

        <div class="sm:w-6/12">
          <div class="mt-10 sm:mt-20 flex items-start space-x-6 xl:w-9/12 w-11/12">
            <img src="../assets/images/tick.svg" alt="tick" class="mt-1" />
            <div class="text-white">
              <h3 class="font-medium text-2xl">
                Payment is made directly to your school
              </h3>
              <p class="mt-4 lg:text-base text-sm">
                Fees approved are paid directly to the school’s account to ensure funds received are used primarily for
                your child’s education
              </p>
            </div>
          </div>
        </div>
      </div>
      <img src="../assets/images/secure-motif.svg" alt="motif" class="absolute -bottom-44 right-0" />
    </div>

    <!-- Mision -->
    <div id="about" class="py-20 font-circular">
      <p
        class="
           text-4xl text-center
          
        "
      >
        Our Mission
      </p>
      <p
        class="font-normal leading-tight text-blue-gray-1 mt-3 xl:w-5/12 lg:w-7/12 mx-auto px-12 text-lg lg:text-xl text-center"
      >
        “We acknowledge every parent’s dream of giving their children quality education. Zoropay is creating an impact
        by building a system that keeps fewer children at home and more children in school.”
      </p>

      <!--  
			<div class="mx-auto text-center sm:mx-0 mt-8 ">
				<button class="cta-button">About Us</button>
			</div>-->
    </div>
    <!-- partnerships -->
    <div class=""></div>
    <!-- testimonials -->
    <!--

		<div class="pt-16 pb-44">
			<h2
				class="section-title mx-auto text-center tracking-tight font-circular"
			>
				What Our Users Say
			</h2>
			<div class="mt-10 flex sm:space-x-14 space-x-5 overflow-x-auto">
				<div class="sm:pl-14"></div>
				<div
					class="sm:w-6/12 w-11/12 bg-blue2 rounded-2xl flex flex-col items-center py-10 flex-shrink-0"
				>
					<img
						src="https://res.cloudinary.com/dq6iuhnpr/image/upload/v1634305775/zoropay/avatar_fck56m.png"
						alt=""
						class="sm:h-24 h-16"
					/>
					<p
						class="mt-7 text-white text-sm sm:w-7/12 w-9/12 mx-auto text-center"
					>
						All my children are enrolled with zero anxiety because
						of Zoropay. Lorem ipsum dolor sut amet
					</p>
					<h4 class="mt-5 text-white font-semibold text-xl">
						Regina Miles
					</h4>
				</div>
				<div
					class="sm:w-6/12 w-11/12 bg-blue2 rounded-2xl flex flex-col items-center py-10 flex-shrink-0"
				>
					<img
						src="https://res.cloudinary.com/dq6iuhnpr/image/upload/v1634305775/zoropay/avatar_fck56m.png"
						alt=""
						class="sm:h-24 h-16"
					/>
					<p
						class="mt-7 text-white text-sm sm:w-7/12 w-9/12 mx-auto text-center"
					>
						All my children are enrolled with zero anxiety because
						of Zoropay. Lorem ipsum dolor sut amet
					</p>
					<h4 class="mt-5 text-white font-semibold text-xl">
						Regina Miles
					</h4>
				</div>
				<div
					class="sm:w-6/12 w-11/12 bg-blue2 rounded-2xl flex flex-col items-center py-10 flex-shrink-0"
				>
					<img
						src="https://res.cloudinary.com/dq6iuhnpr/image/upload/v1634305775/zoropay/avatar_fck56m.png"
						alt=""
						class="sm:h-24 h-16"
					/>
					<p
						class="mt-7 text-white text-sm sm:w-7/12 w-9/12 mx-auto text-center"
					>
						All my children are enrolled with zero anxiety because
						of Zoropay. Lorem ipsum dolor sut amet
					</p>
					<h4 class="mt-5 text-white font-semibold text-xl">
						Regina Miles
					</h4>
				</div>
				<div class="sm:pr-14"></div>
			</div>
		</div>
 -->

    <!-- our partners -->
    <div class="pt-16 pb-44">
      <h2 class="section-title mx-auto text-blue text-center tracking-tight font-circular">
        Partnerships
      </h2>
   
	<div
				class=" mt-10 flex justify-center items-center sm:space-x-20 space-x-5 space-y-5 flex-wrap"
			>
				<img src="../assets/images/sycamore.png" alt="Sycamore"  class="sm:h-20 h-10"/>
				<img src="../assets/images/rmoney.png"   alt="renmoney"  class="sm:h-20 h-10"/>
        <img
          src="https://res.cloudinary.com/zoropayhq/image/upload/v1664550932/landing-page/mpower_t8k2av.png"
          alt="afe"
          class="sm:h-20 h-10"/>
				<img src="../assets/images/vfdgroup.png" alt="VFD"       class="sm:h-20 h-10"/>
        <img src="../assets/images/edfin.png"    alt="EdFin"     class="sm:h-20 h-10"/>
        <img src="https://res.cloudinary.com/zoropayhq/image/upload/v1664550933/landing-page/Prodigyfinance_l8sbab.png" alt="prodigyFin" class="sm:h-20 h-10"/>
        <!-- <img src="https://res.cloudinary.com/zoropayhq/image/upload/v1664550932/landing-page/Schoolrev_w7xzpv.png" alt="schoolrev" class="sm:h-20 h-10"/> -->

			</div>
    </div>
    <Footer />
  </div>
</template>

<script>
import Vue from "vue";
import Footer from "./Footer.vue";
import Nav from "./Nav.vue";

import Embed from "v-video-embed";
export default {
  components: { Nav, Footer },
  methods: {
    login: function() {
      window.location.href = "https://app.zoropay.com/login";
    },
    signUp: function() {
      window.location.href = "https://app.zoropay.com/get-started";
    },
    quickLP: function() {
      window.location.href = "https://App.zoropay.com/";
    },
    Abroad: function() {
      window.location.href = "https://bit.ly/StudyOverseasWithZoropay";
    },
  },
};
Vue.use(Embed);
</script>

<style scoped>
.cta-button {
  @apply bg-blue text-lg text-white border border-blue py-3 px-8 rounded-full focus:outline-none  hover:shadow-lg transition duration-500;
}

.section-title {
  @apply xl:text-6xl text-4xl font-medium text-darkblue sm:w-10/12;
}

.section-sub {
  @apply mt-5 text-blue-gray-1 sm:w-9/12 w-11/12 xl:text-xl;
}
</style>
