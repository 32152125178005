<template>
  <div class="xl:px-36 lg:px-16 sm:px-28 px-5 py-20 bg-blue2 font-circular">
    <div class="flex flex-col lg:flex-row justify-between pb-8">
      <div class="mt-14 flex lg:w-6/12 w-full justify-between">
        <div class="text-white">
          <p class="font-medium">Product</p>
          <div class="mt-4 space-y-2">
            <a href="https://app.zoropay.com/loans/enrollchild" class="footer-link"
              >Tuition Funding</a
            >
            <a href="https://app.zoropay.com/dashboard/overview" class="footer-link"
              >Pocket Money</a
            >
            <a href="https://app.zoropay.com/dashboard/overview" class="footer-link">Trust funds</a>
            <a href="https://app.zoropay.com/affiliated-school" class="footer-link"
              >Affiliated School</a
            >
          </div>
        </div>
        <!-- 
				<div class="text-white">
					<p class="font-medium">Information</p>
					<div class="mt-4 space-y-2">
						<a href="#" class="footer-link">FAQ</a>
						<a href="#" class="footer-link">Blog</a>
						<a href="#" class="footer-link">Support</a>
					</div>
				</div>
			-->
        <div class="text-white">
          <p class="font-medium">Company</p>
          <div class="mt-4 space-y-2">
            <!-- 
					
						<a href="#" class="footer-link">About us</a>
						<a href="#" class="footer-link">Careers</a>
						<a href="#" class="footer-link">Contact us</a>
						-->

            <a href="https://app.zoropay.com/termsandconditions" class="footer-link"
              >Terms and Conditions</a
            >
            <a href="https://app.zoropay.com/privacy" class="footer-link">Privacy Policy</a>
          </div>
        </div>
      </div>
      <!--

			<div class="mt-10 lg:w-4/12 sm:w-8/12 w-full">
				<div class="bg-white bg-opacity-10 px-10 py-8">
					<p class="text-white text-lg font-medium">Subscribe</p>
					<div class="mt-3 flex">
						<input
							type="text"
							class="px-4 py-3 focus:outline-none w-10/12 rounded-l-lg"
							placeholder="Email address"
						/>
						<button
							class="focus:outline-none bg-blue2 p-3 w-2/12 rounded-r-lg flex justify-center items-center"
						>
							<img
								src="../assets/images/arrow-right.svg"
								alt=""
							/>
						</button>
					</div>
					<p class="mt-8 text-white opacity-60 text-xs w-10/12">
						Our goal is to make higher education accessible for
						every parent and student as much as we can.
					</p>
				</div>
			</div>
			-->
    </div>
    <p class="text-gray-200 text-xs pt-8 border-t font-thin tracking-wider ">
      Zoropay is an educational financial technology service developed and powered by Zoropay
      Technologies Company Limited. Zoropay Technologies Company Limited is duly registered with the
      Nigerian Corporate Affairs Commission, with RC Number 1830159. Savings deposits made by
      parents via Zoropay are held by VFD Microfinance Bank; A Microfinance Bank that is NDIC
      insured and also licensed by the Central Bank of Nigeria. All monies provided as loans through
      Zoropay are bankrolled by legal entities duly licensed by the Central Bank of Nigeria. The
      Zoropay trademarks and all the copyrights in Zoropay are owned by Zoropay technologies Company
      Limited.
    </p>
    <div
      class="flex flex-col sm:flex-row items-center pt-8 border-white justify-between border-opacity-20"
    >
      <div class="sm:w-3/12 w-3/12">
        <img src="../assets/images/logo-white.svg" alt="logo" />
      </div>
      <!--
			<div class="space-x-5 text-white text-sm mt-3 sm:mt-0">
				<a class="">Terms</a>
				<a href="https://zoropay.com/privacy" class="">Privacy</a>
			</div>
			-->
      <div class="flex space-x-6 sm:w-3/12 mt-5 sm:mt-0">
        <a
          href="https://www.linkedin.com/company/zoropayy/"
          target="_blank"
          class="hover:opacity-60 "
        >
          <img src="../assets/images/linkedin.svg" alt="" />
        </a>
        <a
          href="https://www.facebook.com/Zoropay-108763544787905/"
          target="_blank"
          class="hover:opacity-60 "
        >
          <img src="../assets/images/facebook.svg" alt="" />
        </a>
        <a
          href="https://www.instagram.com/zoropayafrica/"
          target="_blank"
          class="hover:opacity-60 "
        >
          <div class="rounded-full h-9 w-9 p-2 border-gray-400 border">
            <img src="../assets/images/instagram.svg" alt="" />
          </div>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {},
};
</script>

<style scoped>
.footer-link {
  @apply opacity-75 block hover:opacity-100 transition duration-300;
}
</style>
